.contact-us-wrapper {
    padding: 0 !important;
    border-top: 1px solid #f0ecec;
}

.contact-us {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 991px) {
        flex-direction: column;
        .contact-us-content {
            margin-left: 0;
            max-width: none;
        }
    }
}

.contact-us-sidebar-wrapper {
    padding-bottom: 10% !important;
    padding-top: 3%;
    height: 100%;
    @media (max-width: 991px) {
        padding: 0 !important;
    }
}

.contact-us-sidebar {
    min-width: 350px;
    max-width: 400px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f6f6f6 !important;
    padding: 0 20px;
    padding-top: 30px;
    padding-bottom: 60px;
    @media (max-width: 480px) {
        min-width: 250px;
    }
    h2 {
        color: #00aeef;
    }
    h3 {
        color: #454647;
    }

    @media (max-width: 991px) {
        max-width: none;
    }
}
.contact-us-sidebar-detail {
    margin-top: 10%;
    margin-bottom: 20%;
    @media (max-width: 991px) {
        margin: 10px 0;
    }
}
.contact-phone, .contact-email, .contact-location {
    h3 {
        color: #898b8d;
        text-transform: uppercase;
    }
    margin: 20px 0;
    h4 {
        font-weight: 400;
    }
   
}

.contact-location h4 {
    max-width: 310px;
}

.contact-us-sidebar-wechat {
    @media (max-width: 991px) {
        display: none;
    }
}

.contact-us-sidebar-footer {
    margin-top: 30%;
    display: flex;
    justify-content: center;
    > img {
        margin: 0 10px;
        cursor: pointer;
    }
    @media (max-width: 991px) {
        margin-top: 20px;
        justify-content: flex-start;
        margin-bottom: 20px;
    }
}

.contact-us-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media (min-width: 992px) {
        // border-left: 1px solid #f0ecec;
        padding-top: 3%;
    }
    
}

.contact-us-content {
    max-width: 789px;
    margin-left: 30px;
    padding-top: 20px;
    width: 100%;
    padding-bottom: 10% !important;
}

.form-header {
    > h3 {
        margin-bottom: 10px;
    }
    > h4 {
        margin-bottom: 20px;
    }
}

form {
    display: flex;
    flex-direction: column;
    > input, div > input {
        border: 0;
        border-bottom: 1px solid #898b8d;
    }

    div {
        display: flex;
        > input {
            max-width: 330px;
            width: 50%;
            padding: 10px 0;
            text-indent: 20px;
        }
        @media (max-width: 576px) {
            flex-direction: column;
            > input {
                width: 100%
            };
        }
        
    }
    div > :first-child {
        margin-right: 49px;
        @media (max-width: 991px) {
            margin-right: 0;
        }
    }

    > input {
        padding: 20px 0;
        text-indent: 20px;
        margin-top: 20px;
        max-width: 709px;
    }

    button {
        max-width: 330px;
        border-radius: 10px;
        padding: 15px 0;
        margin-top: 40px;
        border: 2px solid #00aeef;
        background-color: #fff;
    }

    button:hover {
        background-color: #00aeef;
        color: #fff;
        transition: all 0.5s ease-in;
    }
}

.form-header {
    > h3 {
        color: #e2e4e7;
        text-transform: uppercase;
    }
    > p {
        color: #555;
    }
}

.map-wrapper {
    margin-top: 60px;
    width: 100%;
    height: 45%;
    > iframe {
        width: 100%;
        height: 100%;
    }
}

