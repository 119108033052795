
.about-us--container {
    padding: 0;
    border-top: 1px solid #f0ecec;
}
.about-us {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 991px) {
        flex-direction: column;
        .about-body {
            margin-left: 0;
            max-width: none;
        }
    }
}
.about-sidebar {
    min-width: 350px;
    height: 100%;
    padding-top: 20px;
    @media (max-width: 480px) {
        min-width: 250px;
    }
    h2 {
        color: #00aeef;
        font-size: 28px;
        @media (max-width: 1200px) {
            font-size: 22px;
        }
        @media (max-width: 991px) {
            font-size: 21px;
            margin: 0;
        }
    }
    h3 {
        color: #454647;
        // font-size: 18px;
    }
}

.about-body-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10% !important;
    @media (min-width: 992px) {
        border-left: 1px solid #f0ecec;
    }
}


.about-body {
    max-width: 789px;
    margin-left: 30px;
    padding-top: 20px;
    > * {
        color: #1c1c1d;
    }

    .about-body-text {
        margin-bottom: 30px;
        text-align: justify;
        // font-size: 16px;
        // line-height: 28px!important;

        // @media (max-width: 576px) {
        //     line-height: 22px!important;
        //     font-size: 14px;
        // }
    }

    .about-body-img {
        > img {
            width: 100%;
        }
        margin-bottom: 20px;
    }

    @media (max-width: 991px) {
        padding-top: 0;
    }
}


.about-culture-container  {
    background-image: url("../../images/company-culture-bg.png");
    padding: 40px 0;
}

.about-culture-top-title {
    > h2 {
        color: #00aeef;
    }
    margin-bottom: 40px;
}

.culture-card-container {
    @media (min-width: 992px) {
        display: flex;
    }
   @media (max-width: 991px) {
    display: none;
   }

}

.culture-card-swiper {
    @media (min-width: 992px) {
        display: none;
    }
   @media (max-width: 991px) {
    display: flex;
   }
}

.culture-card-wrapper {
    margin: 10px;
    >:first-child {
        margin-left: 0;
    }
}

.culture-card {
    width: 152px;
    height: 180px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    img {
        margin-bottom: 10px;
    }
    h3, h4 {
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
    }
    h3 {
        color: #454647;
        padding: 0 10px;
    }
    h4 {
        color: #bcbdbe;
    }


}

.culture-card-swiper {
    .culture-card {
        width: 100%;
    }
}
.about-culture {
    padding: 0 1rem;
   @media (max-width: 576px) {
    padding: 0 !important;
    h3, h4 {
        font-size: 12px !important;
   }
   .culture-card {
    height: 140px;
}
   
}
}

.culture-content-wrapper {
    padding: 0 1rem;
    @media (max-width: 576px) {
     padding: 0 !important;
     .culture-content-right {
        h3, h4 {
            font-size: 14px !important;
        }
     }
    }
}
.card-active {
  .culture-card {
    background-color: #194189;
    h3, h4 {
        color: #fff;
        
    }
    
  }
}

.culture-icon {
    display: flex;
}

.culture-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.culture-content {
    padding: 60px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 0;
    }
}

.culture-content-left {
    max-width: 250px;
    width: 100%;
    h4 {
        color: #6b6b6b;
        text-transform: uppercase;
    }
    h3 {
        color: #e5eaec;
        text-transform: uppercase;
    }
    // @media (max-width: 991px) {
    //     margin-bottom: 20px;
    // }
}

.culture-content-right {
    display: flex;
    width: 100%;
    > * {
        text-align: center;
    }
    h4 {
        color: #6b6b6b;
    }
    h3 {
      color: #bcbdbe;
    }
    @media (max-width: 991px) {
        width: 100%;
        flex-direction: column;
        
    }
}

.culture-row {
    display: flex;
    @media (min-width:992px) {
        width: 50%;
        justify-content: space-around;
    }
    > div {
        margin: 0 10px;
    }
    @media (max-width: 991px) {
        justify-content: space-between;
        padding: 20px 0;
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
        }
    }
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .culture-card, .culture-content, .card-active { animation: fadeIn 2s; }

  .about-body-title {
    color: #000;
    margin-bottom: 20px;
  }

  .culture-row1 {
    @media (min-width: 992px) {
        width: auto !important;
        min-width: 200px;
    }
}

.culture-content-left {
    h4 {
        color: #194189;
    }
    h3 {
        color: #797a7c;;
    }
}

.about-body-list-wrapper {
   > li {
    list-style:decimal;
    margin-bottom: 10px;
   }
}