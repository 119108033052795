#pt-lang{
  font-family: 'Inter', sans-serif!important;
  /* font-family: Arial, Helvetica, sans-serif!important; */
  
 
}
 p {
    font-size: 16px !important;
    line-height: 28px!important;

  }

  @media (max-width:576px) {
      p {
        line-height: 24px !important;
        font-size: 14px !important;
        

      }
  }
  @media (max-width:370px) {
    p {
      text-align: left !important;
    }
  }

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
li{
  
  list-style: none;
}
a{
  text-decoration: none!important;
}
ul{
  padding-left: 0!important;
}
  

#cn-lang, #pt-lang {
  font-family: 'Noto Sans SC', sans-serif;
}

/* #pt-lang {
  font-family: 'Open Sans', sans-serif !important;
} */

.app {
  min-height: 100vh !important;
}