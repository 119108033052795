.home-abt-container {
  @media (max-width: 550px) {
    padding-top: 530px;
  }
  background-color: #f3f5f8;
}
.home-abt {
  background-color: #f3f5f8;
  width: 50%;
  padding: 50px 0;
  @media (max-width: 991px) {
    width: 100%;
    padding: 24px 0;
  }
}
// .home-about{
//     padding: 20px 0;
// }
.home-contact {
  background-image: url(../../../images/Globe2.png);
  background-position: center;
  background-size: cover;
  padding: 50px 0;
  @media (max-width: 991px) {
    padding-top: 24px;
  }
}
.abt-header-eng {
  color: #d3d2d2;
  margin-bottom: 2px;
}
.home-abt-content {
  width: 60%;
  @media (max-width: 576px) {
    width: 100%;
  }
  @media screen and (min-width: 600px) and (max-width: 991px) {
    width: 70%;
  }
}
.home-abt-img {
  width: 40%;
  @media (max-width: 576px) {
    width: 80%;
  }
  @media screen and (min-width: 600px) and (max-width: 991px) {
    width: 30%;
  }
}
.home-abt-img img {
  width: 100%;
  @media (max-width: 576px) {
    width: 100%;
  }
  object-fit: cover;
  object-position: center;
}
.home-contact {
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
  }
  padding-left: 80px;
  @media (max-width: 991px) {
    padding-left: 0 !important;
  }
  > p {
    font-size: 14px !important;
  }
}
.home-abt-text,
.home-contact-text {
  font-size: 14px !important;
  line-height: 21px!important;
  @media (max-width: 455px) {
    font-size: 12px !important;
  }
}
.home-contact h3 {
  font-size: 20px;
}
.home-abt h3 {
  font-size: 20px;
}
.home-abt-arrow:hover{
  padding-left: 5px;
  transition: all 0.3s ease-in-out;
}

// #pt-lang {
// .home-abt{
//   @media (max-width: 550px) {
//     flex-direction: column;
//   }
// }


// }