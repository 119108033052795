.home-page {
  position: relative;
  margin-top: 76px !important;
  @media (max-width: 991px) {
    margin-top: 60px !important;
  }
  width: 100%;
  height: 580px;
  @media (max-width: 1600px) {
    height: 470px;
  }
  @media (max-width: 1024px) {
    height: 420px;
  }
  @media (max-width: 768px) {
    height: 350px;
  }
  @media (max-width: 500px) {
    height: 300px;
  }
}

.slides {
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  height: 580px;
}

.banner-content {
  position: absolute;
  top: 50%;
  @media (min-width: 1200px) {
    top: 39%;
  }
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes fadeIn {
  from {
    opacity: 0.95;
  }

  to {
    opacity: 1;
  }
}
.banner-headlines {
  color: white;
  text-shadow: 4px 6px 5px rgba(56, 65, 81, 0.8);
  width: 50%;
  @media (max-width: 550px) {
    width: 80%;
  }
}
.banner-headlines h1 {
  line-height: 1.1 !important;
}

#pt-lang {
  .banner-headlines {
    width: 60%;
    @media screen and (min-width: 1090px) and (max-width: 1200px){
      width: 65%;
    }
    @media (max-width: 890px) {
      width: 70%;
    }
    @media (max-width:710px){
      width: 85%;
    }
    @media (max-width: 550px) {
      width: 95%;
    }
    @media (max-width: 550px) {
      h1 {
        font-size: 24px!important;
      }
    }
    @media (max-width: 400px) {
      h1 {
        font-size: 22px!important;
      }
    }
    @media (max-width: 370px) {
      h1 {
        font-size: 20px!important;
      }
    }
  }
}
.banner-headlines h2 {
  @media (max-width: 550px) {
    display: none;
  }
}

.banner-headline-pt {
  //color: #e2e2e2; //f0eff4 ;    //#E2E4E7 ; // #e4e4e4 #E5E7E6 E5E7E6
  //text-shadow: 2px 4px 4px rgba(34, 36, 39, 0.8);
  line-height: 1;
  color: rgba(255, 255, 255, 0.8);
  display: none;
}
.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: 100%;
  width: 100%;
  position: relative;
}

.slider-headline-body {
  width: 100%;
  height: 100%;
  animation: fadeIn 0.7s ease-in;
}
.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  object-position: center;
}

.prev,
.next {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 0.5rem;
  margin-top: -3rem;
  font-size: 40px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
}

.prev,
.next {
  color: rgb(203, 203, 203);
}

.prev:hover,
.next:hover {
  color: rgb(255, 255, 255);
  background-color: #000;
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.prev,
.next {
  @media (max-width: 1100px) {
    display: none;
  }
}

/* Home Product cards */

.home-prod-cards {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 110px;
  @media (max-width: 1200px) {
    display: none;
  }

  .prod-cards-promo:hover {
    height: 205px;
    .prod-card-arrow {
      visibility: visible;
    }
    .prod-card-hover {
      display: block;
    }
    .prod-card-hover img {
      transition: all 0.1s ease-in-out;
    }
    transition: all 0.7s ease-in-out;
    .prod-cards-promo .prod-card-hover {
      //height: 105px;
      height: 105px;
    }
    // .prod-card-hover {

    // }
  }
}

.prod-promo-figure {
  font-size: 40px;
  font-weight: 400;
  color: white;
}
.prod-promo-title {
  line-height: 1;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px!important;
  white-space: nowrap;
}

// Cards

.card-color-1 {
  background-color: #194189;
}
.prod-cards-promo {
  width: 16%;
  height: 90px;
  cursor: pointer;
}

.card-title {
  font-size: 16px;
  color: white;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
}
.eng {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 400;
}

.prod-card-container {
  width: 100%;
  position: relative;
}
.prod-card-arrow {
  visibility: hidden;
}
.prod-card-hover {
  display: none;
  height: 90px;
}
.prod-card-hover img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s ease-in;
}

/*Cards on Hover*/

/* Medium Screen Sizes*/

.prod-card-container-md {
  .prod-card-arrow {
    visibility: visible;
  }
  .prod-cards-promo {
    width: 19%;
    height: 90px;
    cursor: pointer;
    margin-right: 1px;
  }
}
.home-prod-card-md {
  @media (min-width: 1201px) {
    display: none;
  }
  @media (max-width: 550px) {
    display: none;
  }
}

/* Small Screeen Devices*/

.home-prod-card-sm {
  @media (min-width: 551px) {
    display: none;
  }
  .prod-card-hover {
    display: block;
    height: 100%;
  }
  .prod-cards-promo {
    width: 80%;
    @media (max-width: 450px) {
      width: 90%;
    }
    //height: 90px;
    cursor: pointer;
  }
  .prod-card-arrow {
    visibility: visible;
  }
  .prod-card-wrap {
    width: 50%;
  }
  .prod-card-hover {
    width: 50%;
  }
  .prod-card-hover img {
    width: 100%;
    height: 100%;
  }
}

.home-prod-card-small {
  position: absolute;
  top: 300px;
  @media (max-width: 500px) {
    top: 250px;
  }
}
