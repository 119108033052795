.hr-wrapper {
  padding: 0 !important;
  border-top: 1px solid #f0ecec;
  // padding-bottom: 10% !important;
  height: 100%;
}

.hr {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
    .hr-content {
      margin-left: 0;
      max-width: none;
    }
  }
}

.hr-sidebar {
  min-width: 350px;
  max-width: 400px;
  height: 100%;
  padding-top: 20px;
  padding-top: 3%;
  @media (max-width: 480px) {
    min-width: 250px;
  }
  h2 {
    color: #00aeef;
  }
  h3 {
    color: #454647;
  }
}

.hr-content-wrapper {
  width: 100%;
  height: 100%;
  min-height: 50vh;
  padding-bottom: 10% !important;
  @media (min-width: 992px) {
    border-left: 1px solid #f0ecec;
    padding-top: 3%;
  }
}

.hr-content {
  max-width: 789px;
  margin-left: 30px;
  @media (max-width:991px) {
    padding-top: 20px;
  }
  
  height: 100%;
  transition: height linear 5;
  > * {
    color: #1c1c1d;
  }

  .hr-content-title1 {
    color: #e2e4e7;
    text-transform: uppercase;
  }

  .hr-content-title2 {
    color: #000;
  }

  .hr-content-text {
    margin-bottom: 30px;
    text-align: justify;
  }

  .hr-content-img {
    > img {
      width: 100%;
    }
  }
}

.hr-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  > div {
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
    background-color: #eef0f3;
    
    > * {
      font-size: 25px;
      color: #194189;
    }
   
   
  }
  .prev-nxt-btn:hover {
      background-color: #ff9901;
      > * {
        color: white;
      }
      transition: all 0.5s ease-in;
      
    }
}
  
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hr-content {
  animation: fadeIn 2s;
}

.hr-page-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  max-width: 789px !important;
    margin-left: 30px;
    width: 100%;
  @media (max-width: 991px) {
    max-width: none !important;
    margin-left: 0 !important;
  }
}


.hr-page-wrapper >:first-child:hover, .hr-page-wrapper >:last-child:hover {
  background-color: #ff9901;
  > * {
    color: white;
  }
  border: 0;
}
.hr-page-box {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 5px;
  margin: 2px;
  font-size: 14px !important;
  color: #000000DE !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hr-page-box-active {
  background-color: #ff9901;
      > * {
        color: white;
      }
      border: 0;
}


