.news-home {
    display: flex;
    height: 100%;
    width: 100%;
    gap: 10px;
    @media (max-width: 991px) {
        width: 100%;
        flex-direction: row-reverse;
    }

    @media (max-width: 575px) {
        flex-direction: column-reverse;
        gap: 30px;
    }
    .img-wrapper {
        width: 40%;
        @media (max-width: 576px) {
            width: 80%;
        }

        @media screen and (min-width: 600px) and (max-width: 991px) {
            width: 30%;
        }
        @media (max-width: 576px) {
            width: 100%;
        }
    }

    .img {
        width: 100%;
        @media (max-width: 576px) {
            width: 100%;
        }
        object-fit: cover;
        object-position: center;
        animation-name: fadeIn;
        animation-duration: 2s;
    }

    .content-wrapper {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 576px) {
            width: 80%;
        }

        @media screen and (min-width: 600px) and (max-width: 991px) {
            width: 70%;
        }
        @media (max-width: 576px) {
            width: 100%;
        }

        .headline {
            font-size: 20px;
            color: #212529;
            line-height: 24px!important;
        }
        .date {
            font-size: 14px;
            color: #737475;
            margin: 10px 0;
            
        }
        .text {
            font-size: 14px;
            color: #737475;
        }
        .bullets {
            width: 100%;
            display: flex;
            margin-top: 20px;
            .bullet {
                width: 10px;
                height: 10px;
                background-color: rgba(255, 255, 255, 0.9);
                border: 1px solid #8d8c8c;
                z-index: 33;
                margin: 4px;
                border-radius: 10px;
                cursor: pointer;
            }
            .bullet-active {
                background-color: #8d8c8c;
            }
        }
    }


}


@keyframes fadeIn {
    from {
      opacity: 0.95;
    }
  
    to {
      opacity: 1;
    }
  }

.show-news {
    display: block;
}

.hide-news {
    display: none;
}