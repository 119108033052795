.news-comp-wrapper {
    padding: 0 !important;
    border-top: 1px solid #f0ecec;
}

.news-comp {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 991px) {
        flex-direction: column;
    }
}

.news-comp-sidebar-wrapper {
    padding-bottom: 10% !important;
    padding-top: 3%;
    height: 100%;

    @media (max-width: 991px) {
        padding: 0 !important;
    }
}

.news-comp-sidebar {
    min-width: 350px;
    max-width: 400px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f6f6f6 !important;
    padding-top: 30px;
    // padding-bottom: 60px;
    // @media (max-width: 991px) {
    //     padding-bottom: 0;
    //     }
    .top-title {
        padding: 0 20px;
        @media (max-width: 991px) {
            padding: 0;
        }
    }
    @media (max-width: 480px) {
        min-width: 250px;
    }

    h2 {
        color: #00aeef;
    }

    h3 {
        color: #454647;
    }

    @media (max-width: 991px) {
        max-width: none;
    }

    .news-list {
        padding-top: 40px;

        .active {
            width: 100%;
        }

        @media (max-width: 991px) {
            padding-top: 20px;
        }
    }

    .news-detail-card {
        min-height: 80px;
        width: 100%;
        display: flex;
        cursor: pointer;
        padding: 10px 20px;
        @media (max-width: 991px) {
            padding: 10px 0px;
        }
        .content {

            // min-width: 150px;
            @media (max-width: 991px) {
                max-width: 550px;

            }
        }

        .headline {
            width: 100%;
            color: rgb(97, 99, 121);
            font-size: 14px;
            font-family: Inter, sans-serif;
            line-height: 21px;

            @media (max-width: 991px) {
                font-size: 13px;
                line-height: 19px;
            }

            @media (max-width: 767px) {
                font-size: 12px;
                line-height: 18px;
            }
        }

        .date {
            width: 100%;
            color: rgb(173, 173, 173);
            font-size: 13px;
            font-family: Inter, sans-serif;
            line-height: 24px;
            // margin-bottom: 13px;

            @media (max-width: 1024px) {
                font-size: 12px;
                line-height: 22px;
            }

            @media (max-width: 767px) {
                font-size: 11px;
                line-height: 21px;
            }
        }

        >img {
            // max-width: 80px;
            // max-height: 80px;
            // width: 100%;
            // height: 100%;
            width: 80px;
            height: 80px;
            object-fit: cover;
            margin-right: 16px;

            @media (max-width: 991px) {
                // max-width: 113px;
                // max-height: 113px;
                // width: 100%;
                // height: 100%;
                // width: 113px;
                // height: 113px;
            }

            @media (max-width: 767px) {
                // max-width: 177px;
                // max-height: 177px;
                // min-height: 80px;
                // min-width: 80px;
                // height: auto;
                // width: auto;
            }
        }
    }

    .news-detail-card-active {
        background-color: rgba(0, 0, 0, 0.08);
    }
}

.slide-wrapper {

    width: 100%;

    >img {
        width: 100%;
    }
}

.slide-image-wrapper {
    margin: 32px 0px;

    @media (max-width: 1024px) {
        margin-top: 30px;
    }

    @media (max-width: 767px) {
        margin-top: 28px;
    }

    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    .slide-image {
        width: 100%;
        max-height: 500px;
        height: 100%;
        display: none;
    }
}

.left-right-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 0;

    .prev,
    .next {
        cursor: pointer;
    }
}

.slide-image-active {
    display: block !important;
}

.page-number-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    max-width: 789px !important;
    margin-left: 30px;
    width: 100%;

    @media (max-width: 991px) {
        max-width: none !important;
        margin-left: 0 !important;
    }
}


.page-number-box-wrapper>:first-child:hover,
.page-number-box-wrapper>:last-child:hover {
    background-color: #ff9901;

    >* {
        color: white;
    }

    border: 0;
}

.page-number-box {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: 2px;
    font-size: 14px !important;
    color: #000000DE !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-number-box-active {
    background-color: #00aeef;

    >* {
        color: white;
    }

    border: 0;
}

.page-number-box-hide {
    display: none;
}

.page-number-box-show {
    display: block;
}

.news-comp-content {
    padding-top: 30px;
    padding-bottom: 50px;
    max-width: 896px;
    width: fit-content;
    margin-left: 40px;

    @media (max-width: 991px) {
        margin-left: 0;
    }

    @media (max-width: 350px) {
        padding-bottom: 40px !important;
    }

    .headline {
        margin-bottom: 20px;
        color: rgb(34, 35, 52);
        font-family: Raleway, sans-serif;
        font-size: 39px;
        font-weight: 700;
        letter-spacing: -1.5px;
        line-height: 47px;

        @media (max-width: 1024px) {
            font-size: 31px;
            line-height: 37px;
        }

        @media (max-width: 767px) {
            font-size: 25px;
            line-height: 30px;
        }
    }

    .text {
        margin-bottom: 14px;
        color: rgb(97, 99, 121);
        font-family: Inter, sans-serif;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        text-align: justify;

        @media(max-width: 1024px) {
            font-size: 15px;
            line-height: 22px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    .date {
        width: 100%;
        color: rgb(173, 173, 173);
        font-size: 13px;
        font-family: Inter, sans-serif;
        line-height: 24px;
        margin-bottom: 13px;

        @media (max-width: 1024px) {
            font-size: 12px;
            line-height: 22px;
        }

        @media (max-width: 767px) {
            font-size: 11px;
            line-height: 21px;
        }
    }
}