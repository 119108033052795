.product-wrapper {
    padding: 0 !important;
    padding-top: 3% !important;
    padding-bottom: 10% !important;
    @media (max-width: 576px) {
        margin-top: 0px;
    }
    position: relative;
    border-top: 1px solid #f0ecec;
}

.product-bg {
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    bottom: 0;
    background-image: url('../../images/company-culture-bg.png');
    background-size: cover;
}
.product {
    display: flex;
    justify-content: space-between;
    @media (max-width: 769px) {
        flex-direction: column;
    }
    gap: 20px;
   
}
.product-sidebar-wrapper {
    width: 100%;
    z-index: 222 !important;
   @media (min-width: 770px) {
    max-width: 295px;
    min-width: 260px;
   }
    background-color: #f0f4f6;
    @media (max-width: 769px) {
        max-width: 100%;
        min-width: 240px;
    }
}

.product-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 20px;
    @media (max-width: 769px) {
        align-items: flex-start;
    }
    @media (max-width: 576px) {
        padding: 10px;
    }
}
.product-sidebar-title {
    padding: 20px;
    h2 {
        color: #00aeef;
    }

    @media (max-width: 576px) {
        padding: 0 10px;
    }
}

.product-sidebar-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center
}

.product-sidebar-card {
    @media (min-width: 770px) {
        max-width: 230px;
    height: 105px;
    width: 100%;
    }
    @media (max-width: 769px) {
        max-width: 100%;
        height: 60px;
        width: 100%;
    }
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    > * {
        color: #fff !important;
    }
    h3 {
        opacity: 0.66;
        margin-bottom: 5px !important;
    }


    h4 {
        opacity: 1;
    }
    
    cursor: pointer;

    @media (min-width: 770px) {
        h3, h4 {
            font-size: 16px;
            margin: 0;
        }
        margin: 3px 0;
    }
    @media (max-width: 769px) {
        h3, h4 {
            font-size: 14px;
            margin: 0;
        }
        margin: 2px 0;
    }
}

#pt-lang {
    .product-sidebar-card {
        h3 {
            opacity: 1;
        }
        h4 {
                 opacity: 0.88;
         }
    }
}

.product-sidebar-card1 {
    background-color: #194189;
}

.product-sidebar-card2 {
    background-color: #468C98;
}

.product-sidebar-card3 {
    background-color: #B3A394;
}

.product-sidebar-card4 {
    background-color: #F7934C;
}

.product-sidebar-card5 {
    background-color: #048A81;
}

.product-sidebar-card6 {
    background-color: #AEB4A9;
}

.product-content-wrapper {
    z-index: 222 !important;
    background-color: #fff;
    width: 100%;
}

.product-content {

    @media (min-width: 992px) {
        max-width: 940px
    }
}

.product-content-img {
    width: 100%;
    > img {
        width: 100%;
    }
}

.product-content-text-wrapper {
    margin-top: 20px;
    padding: 20px;
    @media (max-width: 576px) {
        padding: 10px;
    }
}

.product-content-title {
    h3 {
        color: #c4c7c4;
    }
    h4 {
       color: #797a7c;
    }
    margin-bottom: 20px;
}

.product-content-body {
   
    h4, h5 {
        color: #454647;
    }
    p {
        color: #1c1c1d;
        text-align: justify;
        font-size: 16px;
        line-height: 28px!important;

        @media (max-width: 576px) {
            line-height: 22px!important;
            font-size: 14px;
        }
    }
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .product-content, .product-arrow { animation: fadeIn 2s; }
