.navbar {
    padding: 0 !important;
    top: 0;
    position: fixed !important;
    background-color: #fff;
    box-shadow: 0px 2px 3px rgba(109, 108, 108, 0.1);
    z-index: 999999;
}

.navbar-logo-menu--wrapper {
    height: 50px;
    white-space: nowrap;
    width: 100%;
    justify-content: space-between;
    position: relative;

    @media (max-width: 991px) {
        height: 40px;
        height: auto;
        min-height: 60px;
    }
}



.navbar--menu-logo {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: relative;
}

.navbar-menu-logo--mobile {
    @media (max-width: 991px) {
        justify-content: flex-end;
    }
}

.navbar--logo {
    display: flex;
    padding: 16px;
    align-items: center !important;
    justify-content: flex-start;
    padding: 0 !important;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.navbar-logo--mobile {
    @media (max-width: 991px) {
        display: none;
    }
}

.navbar-logo--img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.navbar--links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100% !important;
    padding: 0 !important;

    @media (max-width: 991px) {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        padding-top: 34px !important;
        width: 100vw !important;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 !important;
        height: auto;
        min-height: 100vh;
        background-color: #194189;
        color: white;

        >* {
            background-color: #194189;
            color: white;
        }
    }
}

.navbar-bg--blue {
    @media (max-width: 991px) {
        background-color: #194189;
        color: white;

        >* {
            background-color: #194189;
            color: white;
        }
    }
}

.navbar-links--show {
    display: flex;
}

.navbar-lang--wrapper {
    min-height: 76px;
    border-left: 1px solid rgba(179, 179, 179, 0.3);
    padding: 0 25px;
    min-width: 112px;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: 991px) {
        min-height: 60px;
        display: none;
        border: 0;
        align-items: flex-start;
    }

    position: relative;
}

.navbar-lang--mobile {
    @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-self: flex-end !important;
        padding-right: 20px;
        padding-top: 20px;
    }
}

.navbar--lang {
    padding-right: 20px;
    white-space: nowrap;

}

.navbar-lang--icon {
    padding-left: 5px;
}

.navbar-lang--dropdown {
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    position: absolute;

    left: 0;

    >div {
        padding: 0 12px;
        display: flex;
        align-items: center;
    }

    @media (min-width: 992px) {
        top: 76px;
        background-color: rgba(0, 0, 0, .6);

        >* {
            color: #fff;
        }

        >div {
            min-height: 40px;
            border-bottom: 1px dotted rgba(179, 179, 179, 0.3);
        }

        // > div:hover {
        //     background-color: #f2f2f2;
        // }
        >div:first-child {
            border-top: 3px solid #ff9901;
        }
    }

    @media (max-width: 991px) {
        position: relative !important;
        top: 0;
        left: 0;
        background-color: #194189;

        >div {
            min-height: 35px;
            padding: 0;
        }

        >div:first-child {
            padding-top: 10px;
        }
    }

}

.navbar--menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 !important;

    cursor: pointer;

    @media (min-width: 992px) {
        display: none;
        min-height: 76px;
    }

    @media (max-width: 991px) {
        min-height: 60px;
        align-self: flex-start;
        align-items: flex-start;

        >div {
            padding-top: 20px;
        }
    }
}

.navbar-link {
    height: 100%;
    display: flex;
    align-items: center;

    >* {
        padding: 0 10px;
    }

    >div {
        cursor: pointer;
    .active {
        color: #ff9901 !important; 
    }
    }

    @media (max-width: 991px) {
        height: auto;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;

        >div {
            min-height: 50px;
            display: flex;

            width: 100%;
            border-bottom: 1px solid rgba(179, 179, 179, 0.1);
        }

        >div:last-child,
        div:first-child {
            align-items: center;
        }

        >div:last-child {
            border: 0;
        }
        
    }

}

.navbar-link-dropdown--wrapper {
    position: relative;
    // height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    >div:first-child {
        width: 100%;
       @media (min-width: 992px) {
        padding-top: 27px;
        padding-bottom: 27px;
       }
       @media (max-width: 991px) {
        padding-top: 10px;
        padding-bottom: 10px;
       }
    }

    @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        >* {
            padding: 0;
        }

        // padding-top: 12px;
        // padding-bottom: 12px;
    }
}

.navbar-link--dropdown {
    position: absolute;
    left: 0;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;

    > div {
        display: flex;
        align-items: center;
        > * {
            width: 100%;
        }
    }
    .dropdown-item {
        padding: 0 25px;
        width: 100% !important;
    }

    @media (min-width: 992px) {
        top: 72px;
        width: max-content !important;

        >div {
            min-height: 40px;
            border-bottom: 1px dotted rgba(179, 179, 179, 0.3);
        }

        // > div:hover {
        //     background-color: #f2f2f2;
        // }

        >div:last-child {
            border: 0;
        }

        background-color: rgba(0, 0, 0, .6);

        >* {
            color: #fff;
        }

        >div:first-child {
            border-top: 3px solid #ff9901;
        }
    }

    @media (max-width: 991px) {
        position: relative !important;
        display: block;
        top: 0;
        left: 0;
        width: 100%;

        >div {
            min-height: 40px;
            // border-bottom:  1px solid rgba(179, 179, 179, 0.1);
            display: flex;
            align-items: center;
        }

        >div:first-child {
            // padding-top: 20px;
            padding-bottom: 10px;
        }

        >div:last-child {
            border-bottom: 0
        }
    }
}

.navbar-link-dropdown--show {
    max-height: 600px;
}

@media (max-width: 991px) {

    .navbar-link--dropdown div:hover,
    .navbar-link-dropdown--wrapper div:hover {
        color: #f1f1f1;
    }
}

@media (min-width: 992px) {

    .navbar-link div>*,
    .navbar-link-dropdown--wrapper div>*,
    .navbar-lang--wrapper>div>* {
        font-size: 14px;
        color: #222;
        text-decoration: none;
    }

    .navbar-link--dropdown div>*,  
    .navbar-lang--dropdown div {
        font-size: 14px;
        color: #fff;
        text-decoration: none;
    }

    .navbar-link div > *:hover,
    .navbar--lang > *:hover {
        color: #797676;
    }

    .navbar-link--dropdown div > *:hover,
    .navbar-lang--dropdown div:hover {
        color: #cacaca;
    }
}

@media (max-width: 991px) {

    .navbar-lang--wrapper>div>*,
    .navbar-link div>*,
    .navbar-link--dropdown div>*,
    .navbar-link-dropdown--wrapper div>* {
        font-size: 14px;
        color: #fff;
        text-decoration: none;
    }

    .navbar-link--dropdown div > *,
    .navbar-lang--dropdown>div {
        color: #cacaca;
    }

    .navbar-link div > *:hover,
    .navbar-link--dropdown div > * :hover,
    .navbar--lang > *:hover,
    .navbar-lang--dropdown div:hover {
        color: #979494;
    }
}

// .active {
//     color: #ff9901 !important;
// }


