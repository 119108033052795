.banner-common {
  width: 100%;
  height: 320px;
  @media (max-width: 1024px) {
    height: 300px;
  }
  @media (max-width: 600px) {
    height: 250px;
  }
  @media (max-width: 400px) {
    height: 200px;
  }
  position: relative;
  margin-top: 76px;
  @media (max-width: 991px) {
    margin-top: 60px;
  }
}


.banner-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7539390756302521) 0%,
    rgba(0, 0, 0, 0.7035189075630253) 16%,
    rgba(0, 0, 0, 0.5018382352941176) 35%,
    rgba(0, 0, 0, 0) 100%
  );
}

.banner-title {
  position: absolute;
  top: 40%;
  left: 0;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.9);
}

.banner-title-span {
  align-self: center;
  text-transform: uppercase;
}
