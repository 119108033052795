.footer-container{
  background-color: #181528;
}
.footer {
  background-color: #181528;
  >* row{
     padding-left: 0;
     padding-right: 0;
  }
}
.footer-links li span{
  >* {
    font-size: 13px!important;
    
  }
}
.footer-link-item a {
  font-size: 12px;
  color: #c1c1c1;
  @media (max-width: 390px) {
    font-size: 10px;
  }
 
}
.footer-copyright{
  color: #b4b4b4;
   font-size: 11px;
}
.footer-phone, .footer-email, .footer-wechat{
  font-size: 13px;
  color: #c1c1c1;
  white-space: nowrap;
  @media (max-width: 830px) {
    font-size: 12px;
  }
}
.footer-link-item{
  white-space: nowrap;
}
.footer-links .footer-link-item::before{
  content: "|";
  font-weight:100;
  font-size: 14px;
  left: 0;
  color: #8b8b8b;
  display: inline-block;
  padding-right: 12px;
}
.footer-links{
  flex-wrap: wrap;
}
.footer-links .footer-item-1::before{
  content: none;
}
.wechat-qr{
  height: 65px;
  width: 65px;
}
.wechat-qr img{
 width: 100%;
 object-fit: cover;
 object-position: center;
}
.footer-links a{
  color: #c1c1c1;
  text-decoration: none;
}
.footer-links a:hover{
  color: #f2f2f2;
  transition: 1s all ease-in-out;
}
.footer-links a.active{
  color: #FFF;
}

