.bNav {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    > * {
        font-size: 14px !important;
        opacity: 0.7;
    }
    padding: 0 !important;
    @media (max-width: 991px) {
        height: 60px;
    }
    @media (max-width: 576px) {
        height: 50px;
    }
}
.bNav--links {
    display: flex;
    > div {
        cursor: pointer;
    }
    > div:last-child {
        cursor:default;
    }
    @media (max-width: 450px) {
        flex-wrap: wrap;
    }
}
.bNav--link {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    .active {
        color: #222 !important;
    }
    >:first-child {
        margin-right: 5px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #222;
    }

   
}
.bNav-home--icon > * {
    color: #00aeef;
    width: 14px;
    height: 14px;
}

.bNav-triangle--icon > * { 
    color: #898b8d;
}

.bNav-home--icon {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.bNav-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.container-lg {
    height: 100%;
}

.bNav-link--active {
    > * {
        color: #00aeef !important;
    }
}

.bNav--link {
    font-size: 14px !important;
    @media (max-width: 991px) {
        > * {
            font-size: 12px !important;
        }
    }
    @media (max-width: 576px) {
        > * {
            font-size: 10px !important;
        }
    }
    @media (max-width: 400px) {
        > * {
            font-size: 8px !important;
        }
    }
}